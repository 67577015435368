import React from 'react';

import { useRouter } from 'next/router';

import TransText from 'next-translate/TransText';

import Trans from '@components/Trans';
import Underline from '@components/ui/Underline';

import useTranslation from '@hooks/useTranslation';
import { multisiteRouteCustomersPostBeautysleep, multisiteRouteCustomersPostInfoshareacademy, multisiteRouteCustomersPostMondayGroup } from '@static_components/multisiteLinks';
import clsx from 'clsx';

import Testimonial from './Testimonial';
import styles from './WhyGetresponse.module.css';
import SVGBeautysleep from './assets/beautysleep.svg';
import SVGInfoshareAcedemy from './assets/infoshare-academy.svg';
import SVGMonday from './assets/monday.svg';

interface WhyGetresponseProps {
    header: JSX.Element;
    number1: string;
    text1: string;
    number2: string;
    text2: JSX.Element;
    number3: string;
    text3: string;
    number4: string;
    text4: string;
    customers: Array<{
        title: string;
        url: string;
        image: {
            url: string;
            alt?: string;
        };
        description?: string;
    }>;
    customClass?: string;
}

const WhyGetresponse = ({ customers, header, number1, text1, number2, text2, number3, text3, number4, text4, customClass }: WhyGetresponseProps) => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();
    return (
        <section className={clsx(styles.whyGetresponse, customClass ?? '')}>
            <h2 className={styles.header}>
                {header ? (
                    header
                ) : (
                    <Trans
                        i18nKey="whyGetresponseHeader"
                        components={{
                            u: <span />,
                        }}
                        ns="pages/homepage/2024/v1/index"
                    />
                )}
            </h2>
            <ul className={styles.numbers}>
                <li>
                    <em>
                        <Underline>
                            <span dangerouslySetInnerHTML={{ __html: number1 }} />
                        </Underline>
                    </em>
                    <p dangerouslySetInnerHTML={{ __html: text1 }} />
                </li>
                <li>
                    <em>
                        <Underline>
                            <span dangerouslySetInnerHTML={{ __html: number2 }} />
                        </Underline>
                    </em>
                    <div>{text2}</div>
                </li>
                <li>
                    <em>
                        <Underline>
                            <span dangerouslySetInnerHTML={{ __html: number3 }} />
                        </Underline>
                    </em>
                    <p dangerouslySetInnerHTML={{ __html: text3 }} />
                </li>
                <li>
                    <em>
                        <Underline>
                            <span dangerouslySetInnerHTML={{ __html: number4 }} />
                        </Underline>
                    </em>
                    <p dangerouslySetInnerHTML={{ __html: text4 }} />
                </li>
            </ul>
            {customers.length ? (
                <div className={styles.testimonials}>
                    {customers.map((customer, index) => {
                        return (
                            <Testimonial
                                key={index}
                                title={
                                    <TransText
                                        text={customer.title}
                                        components={{
                                            span: <span />,
                                            u: <span />,
                                            br: <br />,
                                        }}
                                    />
                                }
                                quote={
                                    <TransText
                                        text={customer.description ?? ''}
                                        components={{
                                            strong: <strong />,
                                            b: <b />,
                                            br: <br />,
                                        }}
                                    />
                                }
                                logo={
                                    <img
                                        loading="lazy"
                                        alt={customer.image?.alt ?? ''}
                                        src={customer.image.url}
                                    />
                                }
                                link={customer.url}
                                linkLabel={t('caseStudyButton')}
                            />
                        );
                    })}
                </div>
            ) : (
                <div className={styles.testimonials}>
                    <Testimonial
                        title={
                            <Trans
                                i18nKey="testimonialHeader1"
                                components={{
                                    u: <span />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        }
                        quote={t('testimonialQuote1')}
                        logo={
                            <img
                                loading="lazy"
                                alt="infoShare Academy logo"
                                src={SVGInfoshareAcedemy.src}
                                width={SVGInfoshareAcedemy.width}
                                height={SVGInfoshareAcedemy.height}
                            />
                        }
                        link={multisiteRouteCustomersPostInfoshareacademy(locale)}
                        linkLabel={t('caseStudyButton')}
                    />
                    <Testimonial
                        title={
                            <Trans
                                i18nKey="testimonialHeader3_v_b"
                                components={{
                                    u: <span />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        }
                        quote={t('testimonialQuote3_v_b')}
                        logo={
                            <img
                                loading="lazy"
                                alt="infoShare Academy logo"
                                src={SVGMonday.src}
                                width={SVGMonday.width}
                                height={SVGMonday.height}
                            />
                        }
                        link={multisiteRouteCustomersPostMondayGroup(locale)}
                        linkLabel={t('caseStudyButton')}
                    />
                    <Testimonial
                        title={
                            <Trans
                                i18nKey="testimonialHeader2"
                                components={{
                                    u: <span />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        }
                        quote={t('testimonialQuote2')}
                        logo={
                            <img
                                loading="lazy"
                                alt="Beauty Sleep logo"
                                src={SVGBeautysleep.src}
                                width={SVGBeautysleep.width}
                                height={SVGBeautysleep.height}
                            />
                        }
                        link={multisiteRouteCustomersPostBeautysleep(locale)}
                        linkLabel={t('caseStudyButton')}
                    />
                </div>
            )}
        </section>
    );
};

export default WhyGetresponse;
