import React from 'react';

import { Button } from '@components/ui/design2023/Button';

import styles from './Testimonial.module.css';

interface TestimonialProps {
    title: JSX.Element;
    quote?: string | JSX.Element;
    logo: JSX.Element;
    link: string;
    linkLabel: string;
}

const Testimonial = ({ title, quote, logo, link, linkLabel }: TestimonialProps) => {
    return (
        <a
            href={link}
            className={styles.testimonial}
        >
            <div className={styles.logo}>{logo}</div>
            <h3 className={styles.header}>{title}</h3>
            <blockquote className={styles.quote}>{quote}</blockquote>
            <div className={styles.footer}>
                <Button
                    color={'black'}
                    size="medium"
                    label={linkLabel}
                />
            </div>
        </a>
    );
};

export default Testimonial;
